// chamar função ao move o scroll

window.onscroll = () => stickyMenu()

const sticky = '104'
const $navigation = document.querySelector('.wrapper-top')
const $bgNavigation = document.querySelector('.bg-wrapperTop')

function stickyMenu () {
  if (window.pageYOffset >= sticky) { // => comparar se o scroll é maior que o topMenu
    $navigation.classList.add('-fixed') // => add class para fixar menu top
    $bgNavigation.classList.add('-fixed') // => add class para fixar menu top

    // $links.forEach(link => {
    //   link.classList.add('--color')
    // })
  } else {
    $navigation.classList.remove('-fixed')
    $bgNavigation.classList.remove('-fixed')
    // $logotipo.setAttribute('src', logotipoGreen)
    // $links.forEach(link => {
    //   link.classList.remove('--color')
    // })
  }
}
