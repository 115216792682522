const target = document.querySelectorAll('[data-anime]')

function animateScroll () {
  const windowTop = window.pageYOffset + ((window.innerHeight * 3.5) / 4)
  target.forEach(element => {
    if (windowTop > element.offsetTop) {
      element.classList.add('animate')
    } else {
      element.classList.remove('animate')
    }
  })
}

animateScroll()

window.addEventListener('scroll', animateScroll)
