const $servicesNav = document.querySelector('.navigation-services')
let $itemActive = document.querySelector('.navigation-services .item.-active')

$servicesNav.addEventListener('click', event => {
  const $origin = event.target

  if ($origin.classList.contains('link')) {
    const $item = $origin.parentNode

    $itemActive.classList.remove('-active')
    $item.classList.add('-active')
    $itemActive = $item
  }
})

const $menuServices = document.querySelectorAll('.navigation-services .link')
const $boxs = document.querySelectorAll('.content-services > *')

function removeClass () {
  $boxs.forEach($box => {
    $box.classList.remove('visible')
  })
}

$menuServices.forEach(link => {
  link.addEventListener('click', scrollToIdOnClick)
})

function scrollToIdOnClick (event) {
  // Prevenir efeito padrao do browser
  event.preventDefault()

  const to = getScrollTopByHref(event.target)
  displayBlock(to)
}

// Verificar a distância entre o alvo e o topo
function getScrollTopByHref (element) {
  const id = element.getAttribute('href')
  return document.querySelector(id)
}

// Animação do scroll até o alvo
function displayBlock (to) {
  removeClass()
  to.classList.add('visible')
}
